"use client"

import HeaderLayout2 from "@/components/layout/header-layout-2";
import Footer from "@/components/layout/footer-layout-2";
import { Button } from "@/components/ui/button";
import React from 'react';
import dynamic from 'next/dynamic';
import { useTranslations } from "next-intl";

const DotLottieReact = dynamic(
  () => import('@lottiefiles/dotlottie-react').then((mod) => mod.DotLottieReact),
  { ssr: false } // Pastikan tidak dirender di server
);

export default function NotFound() {
  const t = useTranslations("NotFoundPage");
  return (
    <>
      <HeaderLayout2 />
      <main className="relative">
        <section className="mt-[4.5rem] bg-muted py-32 dark:bg-slate-900 lg:mt-[161px]">
          <div className="container text-center">
          <DotLottieReact
          src="/lottie/Page-Not-Found.json"
          loop
          autoplay
          />
            <h1 className="mb-4">{t("title")}</h1>
            <p className="mb-12">
              {t("description")}
            </p>
            <Button size="lg" asChild>
              <a href="/">{t("button")}</a>
            </Button>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}